import React from 'react';
import {Link} from 'gatsby';
import Layout from '../components/layout';
import SEO from "../components/seo";

import './team.scss';

// import teamPhotoCS from '../images/coming-soon.webp';
import teamPhotoMR from '../images/mark-reino.webp';
import teamPhotoJS from '../images/john-sternal.webp';
import teamPhotoJH from '../images/janice-hughes.webp';
import teamPhotoSL from '../images/scott-lindars.webp';
import teamPhotoSD from '../images/saul-darville.webp';
import teamPhotoHL from '../images/holly-larson.webp';
import teamPhotoJW from '../images/jeremy-wilson.webp';
import teamPhotoES from '../images/ed-sweet.webp';
import teamPhotoJT from '../images/jessica-tabor.webp';
import teamPhotoBP from '../images/brian-pink.webp';
import teamPhotoMC from '../images/mitchell-curry.webp';
import teamPhotoSV from '../images/sebastian-velazquez.webp';
import teamPhotoJB from '../images/justin-baronoff.webp';
import teamPhotoKL from '../images/kirk-livingston.webp';
import teamPhotoRS from '../images/rachel-stern.webp';
import teamPhotoSF from '../images/sydney-fields.webp';
import teamPhotoAK from '../images/abigail-kinmon.webp';
import teamPhotoTS from '../images/taryn-smith.webp';
import teamPhotoAE from '../images/ali-embry.webp';


export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
            <div className="team-wrapper">
                <div className="container">
                    <div className="row">
                        <div class="col-12">
                            <h2>MEET OUR TEAM</h2>
                            <p>We're a diverse team of business-minded strategists, artists, storytellers, and communications professionals who are passionate about what we do. Together, we combine technology and art to help our clients, local and global, in a variety of ways including branding, marketing strategy, lead generation, awareness, publicity, and more.</p>
                        </div>
                    </div>
                </div>
                <div className="container photo-wrapper" id="team-parent">
                    <div className="row no-gutters">
                        <Link 
                            to="/team/mark-reino/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-1 order-sm-1 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoMR})`} }
                            id="MRBioItem"
                            >
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Mark Reino</h3>
                                <h4>CEO</h4>
                            </div>
                        </Link>
                        <Link   
                            to="/team/john-sternal/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-2 order-sm-2 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoJS})`} }
                            id="JSBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>John Sternal</h3>
                                <h4>Partner, PR, Social, Research</h4>
                            </div>
                        </Link>
                        <Link   
                            to="/team/ali-embry/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-3 order-sm-1 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoAE})`} }
                            id="AEBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Ali Embry</h3>
                                <h4>Account Executive, Client & Creative Services</h4>
                            </div>
                        </Link>
                        <Link 
                            to="/team/scott-lindars/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-3 order-sm-2 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoSL})`} }
                            id="SLBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Scott Lindars</h3>
                                <h4>Marketing &amp; Business Strategy</h4>
                            </div>
                        </Link>
                    </div>
                    <div className="row no-gutters">
                        <Link   
                            to="/team/ed-sweet/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-1 order-sm-1 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoES})`} }
                            id="ESBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Ed Sweet</h3>
                                <h4>Copywriter</h4>
                            </div>
                        </Link>      
                        <Link  
                            to="/team/justin-baronoff/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-2 order-sm-2 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoJB})`} }
                            id="JBBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Justin Baronoff</h3>
                                <h4>PR Account Executive</h4>
                            </div>
                        </Link>
                        <Link   
                            to="/team/holly-larson/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-3 order-sm-1 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoHL})`} }
                            id="HLBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Holly Larson</h3>
                                <h4>Copywriter</h4>
                            </div>
                        </Link>
                        <Link 
                            to="/team/saul-darville/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-3 order-sm-2 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoSD})`} }
                            id="SDBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Saul Darville</h3>
                                <h4>Creative Director</h4>
                            </div>
                        </Link>
                    </div>
                    <div className="row no-gutters">
                        <Link
                            to="/team/sydney-fields/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-1 order-sm-1 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoSF})`} }
                            id="SFBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Sydney Fields</h3>
                                <h4>PR Assistant</h4>
                            </div>
                        </Link>
                        <Link 
                            to="/team/brian-pink/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-2 order-sm-2 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoBP})`} }
                            id="BPBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Brian Pink</h3>
                                <h4>Sr. Art Director</h4>
                            </div>
                        </Link>
                        <Link   
                            to="/team/janice-hughes/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-2 order-sm-1 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoJH})`} }
                            id="JHBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Janice Hughes</h3>
                                <h4>Copywriter</h4>
                            </div>
                        </Link>
                        <Link
                            to="/team/mitchell-curry/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-4 order-sm-2 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoMC})`} }
                            id="MCBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Mitchell Curry</h3>
                                <h4>Project Manager</h4>
                            </div>
                        </Link>
                    </div>
                    <div className="row no-gutters">  
                        <Link
                            to="/team/sebastian-velazquez/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-1 order-sm-1 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoSV})`} }
                            id="KNBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Sebastian Velazquez</h3>
                                <h4>Sr. Art Director</h4>
                            </div>
                        </Link>
                        <Link
                            to="/team/rachel-stern/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-2 order-sm-2 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoRS})`} }
                            id="RSBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Rachel Stern</h3>
                                <h4>PR Assistant</h4>
                            </div>
                        </Link>
                        <Link
                            to="/team/kirk-livingston/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-3 order-sm-1 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoKL})`} }
                            id="KLBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Kirk Livingston</h3>
                                <h4>Copywriter</h4>
                            </div>
                        </Link>
                        <Link
                            to="/team/abigail-kinmon/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-4 order-sm-2 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoAK})`} }
                            id="AKBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Abigail Kinmon</h3>
                                <h4>Creative & PR Assistant</h4>
                            </div>
                        </Link>
                    </div>
                    <div className="row no-gutters">  
                        <Link   
                            to="/team/jessica-tabor/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-1 order-sm-1 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoJT})`} }
                            id="JTBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Jessica Tabor</h3>
                                <h4>PR Intern</h4>
                            </div>
                        </Link>
                        <Link 
                            to="/team/jeremy-wilson/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-2 order-sm-2 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoJW})`} }
                            id="JWBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Jeremy Wilson</h3>
                                <h4>Application Developer</h4>
                            </div>
                        </Link>
                        <Link
                            to="/team/taryn-smith/"
                            className="col-12 col-sm-6 col-lg-3 order-lg-3 order-sm-1 team-member" 
                            style={ {backgroundImage: `url(${teamPhotoTS})`} }
                            id="TSBioItem">
                            <div className="team-over"></div>
                            <div className="team-copy">
                                <h3>Taryn Smith</h3>
                                <h4>PR Account Executive</h4>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className='bottom-headline container'>
                    <div className="row">
                        <div className="col-12">
                            <h3>We're problem solvers with a passion for service. We deliver results.</h3>
                        </div>
                    </div>
                </div>
            </div>
    </Layout>
)
